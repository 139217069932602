<template>
  <ModalBackground @click="onClickOutside">
    <ModalBody ref="errorModalBody" @toggle="onToggle">
      <Guideline
        class="py-8"
        :title="modalMessage.title"
        :text="modalMessage.message"
      />
    </ModalBody>
  </ModalBackground>
</template>

<script setup lang="ts">
const { modalMessage, closeModal } = useErrorModal();

const emit = defineEmits(["toggleModal"]);

const errorModalBody = ref();

const onClickOutside = (e: MouseEvent) => {
  if (
    errorModalBody.value &&
    !errorModalBody.value.modalBody.contains(e.target as Node)
  ) {
    closeModal();
    emit("toggleModal");
  }
};

const onToggle = () => {
  closeModal();
  emit("toggleModal");
};
</script>
